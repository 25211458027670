import { gql } from '@apollo/client'

import { QUERY_GET_SELECTED_VARIANTS_CLIENT, QUERY_GET_LIST_ITEMS,QUERY_GET_SELECTED_ALL_VARIANT_CLIENT, QUERY_GET_SELECTED_RETURN_FILTERS_CLIENT, QUERY_GET_SELECTED_ITEM_FILTERS_CLIENT, QUERY_GET_VARIANT, QUERY_GET_LIST_ACCOUNTS} from './graphql'

export const typeDefs = gql`
  extend type Variant {
    isSelected: Boolean
  }
  extend type Order {
    isSelected: Boolean
  }
  extend type Mutation {
    ToogleVariantSelectedClient(variant: Variant!, isSelected: Boolean!): [Variant]
    ToogleVariantSelectedClientAllClient(isSelected: Boolean!): [Variant]
    ToogleVariantFilterApplyClient(status: String!): [VariantFilter]
    ToogleItemCheckUpdateClient(updates: updates!): [Variant]
    ToogleOrderSelectedClient(order: Order!, isSelected: Boolean!): [Order]
    ToogleOrderSelectedClientAllClient(isSelected: Boolean!, isNew: Boolean): [Order]
    ToogleOrderFilterApplyClient(status: String!): [OrderFilter]
    ToogleOrderCheckUpdateClient(updates: updates!): [Order]
    ToogleReturnFilterApplyClient(status: String!): [OrderFilter]
    ToogleItemRemoveVariantListClient(variantId: String!): [Variant]
    ToogleAccountListClient(updates: updates!): [Accounts]
  }
`

// const CheckboxCondition = ["Open", "Ready to Ship", "Shipped", "Printed"]
const searchEls = document.getElementsByName("search-input")

export const resolvers = {
  Variant: {
    isSelected: () => false
  },
  Order: {
    isSelected: () => false,
  },
  Mutation: {
    toogleVariantFilterApplyClient: async(_, {status}, {cache}) => {
      try {
        cache.writeQuery({
          query: QUERY_GET_SELECTED_ITEM_FILTERS_CLIENT,
          data: { selectFilterVariants: status }
        })
      } catch (e) {
        console.log(e, "toogleVariantFilterApplyClient")
      }
      return status
    },
    toogleVariantSelectedClient: async(_, {variant, isSelected}, {cache}) => {
      try {
        const { selectedVariants } = cache.readQuery({
          query: QUERY_GET_SELECTED_VARIANTS_CLIENT
        })
        let newSelectedVariants = [...selectedVariants]
        const selectedVariantIdx = newSelectedVariants.findIndex(selectedVariant => selectedVariant.id === variant.id) 
        if(isSelected) {
          if(selectedVariantIdx < 0)  newSelectedVariants.push(variant)
        }else {
          if(selectedVariantIdx >= 0)  newSelectedVariants.splice(selectedVariantIdx, 1)
        }
        cache.writeQuery({
          query: QUERY_GET_SELECTED_VARIANTS_CLIENT,
          data: { selectedVariants: newSelectedVariants }
        })
      } catch (e) {
        console.log(e, "toogleVariantSelectedClient")
      }
      return variant
    },
    toogleVariantSelectedClientAllClient: async(_, {isSelected, isNew}, {cache}) => {
      const _search = (searchEls.length > 0 ? searchEls[0].value : '')
      const filterByEl = document.getElementById('filter-final')
      const _filterBy =  filterByEl ? (filterByEl.innerHTML !== '' ? JSON.parse(filterByEl.innerHTML) :  {}) : {}

      let newSelectedVariants = [], selectedVariants = []

      try {
        const { selectedVariants } = cache.readQuery({
          query: QUERY_GET_SELECTED_VARIANTS_CLIENT
        })
        newSelectedVariants = [...selectedVariants]
      } catch (e) {
        console.log(e, "toogleVariantSelectedClientAllClient newSelectedVariants")
      }
      
      if(cache.data.data.ROOT_QUERY['variants({"filterBy":'+JSON.stringify(_filterBy)+',"first":50,"search":"'+_search+'"})']) {
        try {
          const {variants} = cache.readQuery({
            query: QUERY_GET_LIST_ITEMS,
            variables: { 
              first: 50,
              search: _search,
              filterBy: _filterBy
            }
          })

          if(isSelected) {
            let result = []
            newSelectedVariants = variants.edges.forEach(variant => {
              const variantData = {
                id: variant.node.id,
                sku: variant.node.sku,
                mainImageUrl: variant.node.mainImageUrl,
                warehouseSpaces: variant.node.warehouseSpaces,
                masterCatalog: variant.node.masterCatalog,
                listings: variant.node.listings
              }
              if(isNew) {
                result.push(variantData)
              } else {
                const selectedVariantsIdx = selectedVariants.findIndex(el => el.id === variant.node.id)
                if(selectedVariantsIdx < 0) result.push(variantData)
              }
            })
            newSelectedVariants = isNew ? result : ([...selectedVariants, ...result])
          }else {
            newSelectedVariants = []
          }

          cache.writeQuery({
            query: QUERY_GET_SELECTED_ALL_VARIANT_CLIENT,
            data: { selectAllVariant: isSelected }
          })
  
          cache.writeQuery({
            query: QUERY_GET_SELECTED_VARIANTS_CLIENT,
            data: { selectedVariants: newSelectedVariants }
          })
  
        } catch (e) {
          console.log(e, "toogleVariantSelectedClientAllClient variants")
        }

        return newSelectedVariants
      }
    },
    toogleItemCheckUpdateClient: (_, {updates}, {cache}) => {
      const _search = (searchEls.length > 0 ? searchEls[0].value : '')
      const filterByEl = document.getElementById('filter-final')
      const _filterBy =  filterByEl ? (filterByEl.innerHTML !== '' ? JSON.parse(filterByEl.innerHTML) :  {}) : {}

      /* update right panel */
      const newUpdatesVariant = [...updates]
      newUpdatesVariant.forEach(update => {
        try {
          // const {variant} = cache.readQuery({
          //   query: QUERY_GET_VARIANT,
          //   variables: { 
          //     id: parseInt(update.node.id)
          //   }
          // })
          const _update = Object.assign({})
          const excludeArr = ['config']
          Object.entries(update.node).forEach( ([key, value]) => {
            if(!excludeArr.includes(key)) _update[key] = value
          })

          cache.writeQuery({
            query: QUERY_GET_VARIANT,
            variables: { 
              id: parseInt(_update.id)
            },
            data: { variant: _update }
          })
        } catch (e) {
          console.log(e, 'toogleItemCheckUpdateClient right')
        }
      })

      /* update left panel */
      if(cache.data.data.ROOT_QUERY['variants({"filterBy":'+JSON.stringify(_filterBy)+',"first":50,"search":"'+_search+'"})']) {
        try {
          const {variants} = cache.readQuery({
            query: QUERY_GET_LIST_ITEMS,
            variables: { 
              first: 50,
              search: _search,
              filterBy: _filterBy
            }
          })
          let newUpdates = [...updates]
          const newVariants = variants.edges.map(_variant => {
            const newUpdateIdx = newUpdates.findIndex(_update => _update.node.id === _variant.node.id)
            if(newUpdateIdx >= 0)  {
              const newUpdate = Object.assign({}, newUpdates[newUpdateIdx])
              newUpdates.splice(newUpdateIdx, 1)

              const _updateList = Object.assign({})
              // const excludeArr = ['quantities']
              // Object.entries(newUpdate.node).forEach( ([key, value]) => {
              //   if(!excludeArr.includes(key)) _updateList[key] = value
              // })

              let _newUpdate = newUpdate
              _newUpdate.node = _updateList

              return _newUpdate
            }else{
              return _variant
            }
          })
          let lastNewVariants = []
          if(newUpdates.length) {
            let currentItem = ''
            const WrapperEl = document.querySelectorAll('._refWrapper')
            const containerEl = WrapperEl[0].querySelectorAll('._refContainer')
            const selectDivEl = containerEl[0].querySelectorAll('div')
            if(selectDivEl.length) {
              currentItem = selectDivEl[0].getAttribute('data-value')
            }
            const LastNewUpdates = []
            newUpdates.forEach(updates => {
              const newVariantAt = updates.node.itemName

              const _updateListVariant = Object.assign({})
              // const excludeArr = ['quantities']
              // Object.entries(updates.node).forEach( ([key, value]) => {
              //   if(!excludeArr.includes(key)) _updateListVariant[key] = value
              // })

              let _newUpdates = updates
              _newUpdates.node = _updateListVariant

              if(newVariantAt === currentItem) LastNewUpdates.push(_newUpdates)
            })
            lastNewVariants = [...LastNewUpdates, ...newVariants]
          }else {
            lastNewVariants = newVariants
          }
          let newVariant = variants
          newVariant.edges = lastNewVariants
          newVariant.updateFe = !variants.updateFe
          cache.writeQuery({
            query: QUERY_GET_LIST_ITEMS,
            variables: { 
              first: 50,
              search: _search,
              filterBy: _filterBy
            },
            data: { variants: newVariant }
          })
        } catch (e) {
          console.log(e, "toogleItemCheckUpdateClient")
        }
      }

      return updates
    },
    toogleReturnFilterApplyClient: async(_, {status}, {cache}) => {
      try {
        cache.writeQuery({
          query: QUERY_GET_SELECTED_RETURN_FILTERS_CLIENT,
          data: { selectReturnFilters: status }
        })
      } catch (e) {
        console.log(e, "toogleReturnFilterApplyClient")
      }
      
      return status
    },
    toogleItemRemoveVariantListClient: async(_, {variantId}, {cache}) => {
      const _search = (searchEls.length > 0 ? searchEls[0].value : '')
      const filterByEl = document.getElementById('filter-final')
      const _filterBy =  filterByEl ? (filterByEl.innerHTML !== '' ? JSON.parse(filterByEl.innerHTML) :  {}) : {}
      let RemoveVariant = null

      if(cache.data.data.ROOT_QUERY['variants({"filterBy":'+JSON.stringify(_filterBy)+',"first":50,"search":"'+_search+'"})']) {
        try {
          const {variants} = cache.readQuery({
            query: QUERY_GET_LIST_ITEMS,
            variables: { 
              first: 50,
              search: _search,
              filterBy: _filterBy
            }
          })
  
          let newVariant =  Object.assign({}, variants)
          const newVariantEdges = [...newVariant.edges]
  
          const RemoveVariantIdx = newVariantEdges.findIndex(_variant => _variant.node.id === variantId)
  
          if(RemoveVariantIdx >= 0)  {
            RemoveVariant = newVariantEdges.filter(_variant => _variant.id === variantId)
            newVariantEdges.splice(RemoveVariantIdx, 1)
  
            newVariant.edges = newVariantEdges
            newVariant.updateFe = !variants.updateFe
  
            cache.writeQuery({
              query: QUERY_GET_LIST_ITEMS,
              variables: { 
                first: 50,
                search: _search,
                filterBy: _filterBy
              },
              data: { variants: newVariant }
            })
  
          }
        } catch (e) {
          console.log(e, "toogleItemRemoveVariantListClient")
        }

        // const newVariants = variants.edges.map(_variant => {
        //   const newUpdateIdx = newUpdates.findIndex(_update => _update.node.id === _variant.node.id)
        //   if(newUpdateIdx >= 0)  {
        //     const newUpdate = Object.assign({}, newUpdates[newUpdateIdx])
        //     newUpdates.splice(newUpdateIdx, 1)
        //     return newUpdate
        //   }else{
        //     return _variant
        //   }
        // })
      }

      return RemoveVariant
    },
    toogleAccountListClient: async(_, {updates}, {cache}) => {
      let newAccounts = []
      try {
        const { accounts } = cache.readQuery({
          query: QUERY_GET_LIST_ACCOUNTS
        })
        newAccounts = Array.from(accounts)
        if(updates) {
          let newAccountsMap = newAccounts.map(acc => {
            const _acc = Object.assign({}, acc) 
            const updatesIdx = updates.findIndex(upd => upd.channel_association_id === parseInt(acc.id))
            if(updatesIdx >= 0) _acc.isSyncing = updates[updatesIdx].is_syncing
            return _acc
          })
          newAccounts = newAccountsMap
          cache.writeQuery({
            query: QUERY_GET_LIST_ACCOUNTS,
            data: { accounts: newAccountsMap }
          })
        }
      } catch (e) {
        console.log(e, "ToogleAccountListClient")
      }
      return newAccounts
    },
  }
}
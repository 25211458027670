import { css } from 'styled-components'

export const clearList = css`
  list-style: none;
  padding: 0;
  margin: 0;
`
export const responseWidth = css `
  width: 100%;
`
export const elipsis = css `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 100%;
  width: auto;
`
export const multiElipsis = css`
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-word;
`
export const boxBase = css`
  background-color: var(--pri-clr-bg);
  box-shadow: var(--pri-shd-bx);
  border-radius: var(--pri-rd);
  border: 1px solid var(--lay-clr-ln);
`
export const dropBase = css`
  background-color: var(--pri-clr-bg);
  border: 1px solid var(--sec-clr-ln);
  box-shadow: var(--sec-shd-bx);
  border-radius: var(--sec-rd);
`
export const headTable = css`
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: capitalize;
`
export const formLabel = css`
  color: var(--hd-clr);
  font-weight: 600;
  font-size: 13px;
  text-transform: capitalize;
`
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import IconXls from '../../assets/images/icons/xls.svg'
import { multiElipsis } from '../../assets/stylesheets/bases.style'

const ClearStyles = css`
  color: var(--pri-clr-lnk);
  &, &:hover {
    text-decoration: none;
  }
  &:hover {
    color: var(--pri-clr-lnk__hvr);
  }
`
const ClearRStyles = css`
  color: var(--pri-clr-lnk);
  &, &:hover {
    text-decoration: none;
  }
  &:hover {
    color: var(--sec-clr-lnk__hvr);
  }
`
const ButtonStyles = css`
  position: relative;
  display: inline-grid;
  padding: 0 16px;
  border-radius: var(--sec-rd);
  outline: none;
  height: 30px;
  align-items: center;
  transform-origin: 100% 0%;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  &, &:hover {
    text-decoration: none;
  }
`

const getLinkModifiedStyled = ({mode, fontSize, activated, elipsis, shadow, iconRight, ...props}) => {
  let modifiedStyled = ""
  if(shadow) modifiedStyled += `
    box-shadow: var(--act-shd-bx);
  `

  if(mode === 'clear') modifiedStyled += ClearStyles
  else if(mode === 'clearR') modifiedStyled += ClearRStyles
  else if(mode === 'blue') modifiedStyled += `
    &, &:hover {
      color: var(--pri-clr-lnk__hvr);
      text-decoration: none;
    }
  `
  else if(mode === 'trans') modifiedStyled += ButtonStyles + `
    height: auto;
    padding: 0;
    opacity: 1;
    &, &:hover { 
      color: var(--pri-clr-lnk__hvr);
      text-decoration: none;
    }  
    &:hover { opacity: .9; }
  `
  else if(mode === 'hover') modifiedStyled += ButtonStyles + `
    height: auto;
    width: max-content;
    padding: 3px 6px;
    position: relative;
    left: -6px;
    color: initial;
    grid-gap: 4px;
    &:hover { 
      border-radius: var(--ter-rd);
      box-shadow: 1px 1px var(--pri-clr-ln);
      background-color: var(--mt-clr-bg__fc);
      text-decoration: none;
    }
  `
  else if(mode === 'whiteB') modifiedStyled += ButtonStyles + `
    color: var(--ter-clr);
    background-color: var(--cl-clr-bg);
    border: 1px solid var(--sec-clr-ln);
    &, &:hover { 
      background-color: var(--cl-clr-bg__hvr);
    }  
  `
  else if(mode === 'redB') modifiedStyled += ButtonStyles + `
    color: var(--act-clr);
    background-color: var(--act-clr-bg);
    &:hover {
      background-color: var(--act-clr-bg__hvr);
    } 
  `
  else if(mode === 'greyB') modifiedStyled += ButtonStyles + `
    color: var(--act-clr);
    background-color: var(--mt-clr-bg__fc);
    &:hover {
      background-color: var(--cl-clr-bg__hvr);
    } 
  `

  if(fontSize) modifiedStyled += ` font-size: ${fontSize};`
  if(activated) {
    modifiedStyled += `
      color: var(--sta-clr);
      font-weight: 400;
    `
    if(mode === 'clear' || mode === 'clearR') modifiedStyled += `
      color: var(--pri-clr-lnk__act);
      font-weight: 600;
    `
  }
  if(elipsis) modifiedStyled += `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    max-width: 100%;
    width: auto;
    ${multiElipsis}
  `
  if(iconRight === 'xls') modifiedStyled += `
    position: relative;
    padding-right: 21px;
    &:before { 
      content: url(${IconXls}); 
      position: absolute;
      right: 0px;
      top: -4px;
      width: 18px;
      height: 18px;
    }
  `
  return modifiedStyled
}

export const LinkContainer = styled(Link)`
  &._refHoverLink {
    padding: 3px 0px 0px 6px;
    position: relative;
    top: -3px;
  }
  ${getLinkModifiedStyled}
`